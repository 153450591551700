var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "listar-segurado pr-4" },
    [
      _c(
        "div",
        { staticClass: "m-1 mr-4 px-0" },
        [
          _c("breadcrumb", {
            attrs: { title: "Segurados", actualPage: "Lista de segurados" }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-3 mt-8 mb-10 gap-3 w-full md:w-4/5 lg:w-3/5 lp:w-1/2"
        },
        [
          _c("filtro", { ref: "filtro", attrs: { isFilter: _vm.filtro } }, [
            _c("div", { staticClass: "pt-2 pb-6 px-4" }, [
              _c(
                "div",
                { staticClass: "flex flex-col mt-4 md:mt-8 md:flex-row" },
                [
                  _c("vs-input", {
                    staticClass: "w-full mt-4 md:w-3/5 md:pr-3 md:m-0",
                    attrs: {
                      size: "large",
                      "label-placeholder": "Nome do Segurado"
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.listarSegurados(true)
                      }
                    },
                    model: {
                      value: _vm.filter.insuredName,
                      callback: function($$v) {
                        _vm.$set(_vm.filter, "insuredName", $$v)
                      },
                      expression: "filter.insuredName"
                    }
                  }),
                  _c("vs-input", {
                    staticClass: "w-full mt-8 md:w-2/5 md:m-0",
                    attrs: {
                      size: "large",
                      "label-placeholder": "CNPJ Segurado"
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.listarSegurados(true)
                      }
                    },
                    model: {
                      value: _vm.filter.insuredDocument,
                      callback: function($$v) {
                        _vm.$set(_vm.filter, "insuredDocument", $$v)
                      },
                      expression: "filter.insuredDocument"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex flex-row-reverse mt-4" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: { color: "primary", type: "filled" },
                      on: {
                        click: function($event) {
                          return _vm.listarSegurados(true)
                        }
                      }
                    },
                    [_vm._v("Aplicar filtro")]
                  ),
                  _c(
                    "vs-button",
                    {
                      attrs: {
                        color: "primary",
                        type: "filled",
                        icon: "clear"
                      },
                      on: {
                        click: function($event) {
                          return _vm.limparFiltros()
                        }
                      }
                    },
                    [_vm._v("Limpar filtro")]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-width-auto",
          staticStyle: { "word-wrap": "break-word" },
          attrs: {
            title:
              "Você ainda tem documentos pendentes e/ou aguardando análise pela seguradora  ",
            active: _vm.modalDocuments
          },
          on: {
            "update:active": function($event) {
              _vm.modalDocuments = $event
            }
          }
        },
        [
          _c(
            "vs-button",
            {
              staticClass: "w-full sm:w-7/12 sm:float-right",
              attrs: { "router-link": "", to: "/corretor" },
              on: {
                click: function($event) {
                  _vm.modalDocuments = false
                }
              }
            },
            [_vm._v("\n      Ir para dados da corretora\n    ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content pt-2" },
        [
          _vm.segurados.length > 0
            ? [
                _c(
                  "vs-table",
                  {
                    staticClass: "table-list",
                    attrs: {
                      noDataText: "Nenhum segurado encontrado",
                      data: _vm.segurados || [],
                      hoverFlat: true
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var data = ref.data
                            return _vm._l(data, function(tr, indextr) {
                              return _c(
                                "vs-tr",
                                {
                                  key: indextr,
                                  attrs: {
                                    data: tr,
                                    state:
                                      data[indextr].InsuredIsBlocked === true
                                        ? "danger"
                                        : null
                                  }
                                },
                                [
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: {
                                        data: data[indextr].InsuredUniqueId
                                      }
                                    },
                                    [
                                      _c(
                                        "vs-dropdown",
                                        {
                                          staticClass: "cursor-pointer",
                                          attrs: {
                                            "vs-custom-content": "",
                                            "vs-trigger-click": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              attrs: {
                                                color: "dark",
                                                type: "flat",
                                                title: "Ações",
                                                disabled: _vm.profileHelpers.checkPermission(
                                                  [
                                                    _vm.profileHelpers
                                                      .menuFuncionalitiesEnum
                                                      .GERENCIAR_SEGURADOS,
                                                    _vm.profileHelpers
                                                      .menuFuncionalitiesEnum
                                                      .VISUALIZAR_SEGURADO
                                                  ]
                                                )
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "onpoint-dots-three-vertical icon-dots-three-vertical",
                                                attrs: { role: "button" }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "vs-dropdown-menu",
                                            { staticStyle: { width: "200px" } },
                                            [
                                              _vm.profileHelpers.checkPermission(
                                                [
                                                  _vm.profileHelpers
                                                    .menuFuncionalitiesEnum
                                                    .VISUALIZAR_SEGURADO
                                                ]
                                              )
                                                ? _c(
                                                    "vs-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.goToInsuredDetail(
                                                            data[indextr]
                                                              .InsuredUniqueId
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-base px-6 py-2"
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v("Visualizar")
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.profileHelpers.checkPermission(
                                                [
                                                  _vm.profileHelpers
                                                    .menuFuncionalitiesEnum
                                                    .GERENCIAR_SEGURADOS
                                                ]
                                              )
                                                ? _c(
                                                    "vs-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openModalBlock(
                                                            data[indextr]
                                                              .InsuredId,
                                                            !!data[indextr]
                                                              .InsuredIsBlocked
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-base px-6 py-2"
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                !data[indextr]
                                                                  .InsuredIsBlocked
                                                                  ? "Bloquear"
                                                                  : "Desbloquear"
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-left",
                                      attrs: {
                                        data: data[indextr].InsuredDocument
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-semibold" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("maskCpfCnpj")(
                                                  data[indextr].InsuredDocument
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].InsuredName }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-semibold" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                data[indextr].InsuredName
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].InsuredBranch
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-semibold" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                data[indextr].IsPrivate
                                                  ? "Privado"
                                                  : "Publico"
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("vs-td", [
                                    _c(
                                      "span",
                                      { staticClass: "font-semibold" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              data[indextr].InsuredIsBlocked
                                                ? "Sim"
                                                : "Não"
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("vs-td", [
                                    data[indextr].InsuredIsBlocked
                                      ? _c(
                                          "span",
                                          { staticClass: "font-semibold" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    data[indextr]
                                                      .InsuredBlockDate,
                                                    "DD/MM/YYYY"
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ],
                                1
                              )
                            })
                          }
                        }
                      ],
                      null,
                      false,
                      3118310644
                    )
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", { staticClass: "text-center" }, [
                          _vm._v("Ações")
                        ]),
                        _c("vs-th", { staticClass: "text-left" }, [
                          _vm._v("CPF/CNPJ")
                        ]),
                        _c("vs-th", { staticClass: "text-left" }, [
                          _vm._v("Nome")
                        ]),
                        _c("vs-th", [_vm._v("Ramo")]),
                        _c("vs-th", [_vm._v("Bloqueado")]),
                        _c("vs-th", [_vm._v("Data bloqueio")])
                      ],
                      1
                    )
                  ],
                  2
                ),
                _vm.numberOfPages > 1
                  ? _c("vs-pagination", {
                      staticClass: "mt-5",
                      attrs: { total: _vm.numberOfPages },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  : _vm._e()
              ]
            : [_vm._m(0)]
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: { title: _vm.modalTitle, active: _vm.openModalBlockInsured },
          on: {
            "update:active": function($event) {
              _vm.openModalBlockInsured = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vw-row mt-3" }, [
            !_vm.isBlocked
              ? _c(
                  "div",
                  { staticClass: "vx-col pt-5 w-full mt-2" },
                  [
                    _c("label", { staticClass: "font-semibold" }, [
                      _vm._v("Justificativa")
                    ]),
                    _c("vs-textarea", {
                      model: {
                        value: _vm.justifyBlock,
                        callback: function($$v) {
                          _vm.justifyBlock = $$v
                        },
                        expression: "justifyBlock"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-default mr-5",
                    on: {
                      click: function($event) {
                        return _vm.closeModalBlock()
                      }
                    }
                  },
                  [_vm._v("\n          Cancelar\n        ")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "button-primary",
                    on: {
                      click: function($event) {
                        return _vm.blockInsured()
                      }
                    }
                  },
                  [_vm._v("\n          Confirmar\n        ")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto text-center mt-32"
      },
      [
        _c("p", [
          _c("span", { staticClass: "material-icons text-6xl" }, [
            _vm._v("description")
          ])
        ]),
        _c("p", { staticClass: "text-center" }, [
          _vm._v(
            "\n          Você ainda não possui nenhum segurado cadastrado.\n        "
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }